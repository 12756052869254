<template>
  <div class="auth">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  mounted() {
    document.body.classList.add("auth-layout");
  },

  unmounted() {
    document.body.classList.remove("auth-layout");
  },
};
</script>

<style scoped lang="scss">
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
