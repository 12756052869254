import API from "@/api";

const actions = {
  loadTeacherInformation(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/partners/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTeacherCourses(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/partners/${data.teacher_id}/courses`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
