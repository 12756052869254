import API from "@/api";
import moment from "moment";
import router from "@/router";

const state = {
  user: "",
};

const mutations = {
  SET_USER_INFORMATION(store, payload) {
    store.user = payload;

    if (store.user.birth_date) {
      store.user.birth_date = moment(payload.birth_date, "YYYY-MM-DD").format(
        "DD.MM.YYYY"
      );
    }
  },
};

const actions = {
  getAuthMethods(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/methods`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendOtpRequest(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/otp`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loginUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/user/login`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyUserPhone(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/otp/verify`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  registerUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/user/register`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadProfileInformation({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/me/info`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 406) {
            router.push({
              name: "AuthView",
              query: { status: "banned" },
            });
          }
          reject(error);
        });
    });
  },

  updateProfileInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/me/update`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadUserAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/me/media`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteUserAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      API.delete(`api/users/me/media`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkPromoCode(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/referrer`, { params: { promocode: data } })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserReferrals(_, params) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/me/referrals`, { params: params })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
