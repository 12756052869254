<template>
  <router-view />

  <Transition name="slide-fade">
    <div class="notification" v-if="notification">
      <button type="button" @click="clearNotification">
        <i class="icon icon-close" />
      </button>

      <i
        class="icon"
        :class="`icon-${
          notification.type === 'success' ? 'done' : 'close-circle'
        }`"
      />

      <div class="notification__description">
        <h3>{{ notification.name }}</h3>

        <p v-if="notification.description">{{ notification.description }}</p>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "app",

  computed: {
    ...mapState({
      notification: (state) => state.system.notification,
    }),
  },

  methods: {
    ...mapMutations({
      setPageWidth: "system/SET_PAGE_WIDTH",
    }),
  },

  created() {
    window.addEventListener("resize", this.setPageWidth);
    this.setPageWidth();
  },

  unmounted() {
    window.removeEventListener("resize", this.setPageWidth);
  },
};
</script>

<style scoped lang="scss">
.notification {
  position: fixed;
  right: 20px;
  top: 100px;
  background: $background-light;
  border: 1px solid $stroke-fourth;
  @include main-shadow();
  border-radius: 12px;
  padding: ($base-space * 4);
  width: 320px;
  z-index: 100;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: $base-space * 3;

  > button {
    position: absolute;
    top: 12px;
    right: 12px;
    > i {
      width: 24px;
      height: 24px;
      background-color: $text-dark-third;
    }
  }

  > i {
    width: 40px;
    height: 40px;
    background-color: $text-success;

    &.icon-close-circle {
      background-color: $text-error;
    }
  }

  &__description {
    > h3 {
      @include m-text-medium();
      color: $text-dark-primary;
    }

    > p {
      margin: ($base-space / 2) 0 0;
      @include s-text-regular();
      color: $text-dark-third;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(360px);
}
</style>
