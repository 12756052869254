import * as mediasoupClient from "mediasoup-client";

export function getDataTimeString() {
  const d = new Date();
  const date = d.toISOString().split("T")[0];
  const time = d.toTimeString().split(" ")[0];
  return `${date}-${time}`;
}

function getUUID() {
  const uuid4 = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

  if (localStorage.uuid) {
    return localStorage.uuid;
  }

  localStorage.uuid = uuid4;
  return uuid4;
}

export function getPeerUUID() {
  if (localStorage.getItem("peer_uuid")) {
    return localStorage.getItem("peer_uuid");
  }

  const peer_uuid = getUUID();
  localStorage.setItem("peer_uuid", peer_uuid);
  return peer_uuid;
}

export function isMobile(userAgent) {
  return !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
    userAgent || ""
  );
}

export function isTablet(userAgent) {
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
}

export function isIpad(userAgent) {
  return /macintosh/.test(userAgent) && "ontouchend" in document;
}

export async function loadDevice(routerRtpCapabilities) {
  let device;
  try {
    device = new mediasoupClient.Device();
  } catch (error) {
    if (error.name === "UnsupportedError") {
      console.error("Browser not supported");
    } else {
      console.error("Browser not supported: ", error);
    }
  }
  await device.load({
    routerRtpCapabilities,
  });
  return device;
}
