import API from "@/api";

const state = {
  window_width: 0,
  notification: "",
  notification_timer: 0,
};

const mutations = {
  SET_PAGE_WIDTH(store) {
    store.window_width = window.innerWidth;
  },

  SET_NOTIFICATION(store, payload) {
    if (store.notification) {
      clearTimeout(store.notification_timer);
      store.notification_timer = 0;
      store.notification = "";
    }

    store.notification = payload;
    store.notification_timer = setTimeout(() => {
      store.notification = "";
      store.notification_timer = 0;
    }, 2000);
  },
};

const actions = {
  sendSupportRequest(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/feedback`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
