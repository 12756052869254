import API from "@/api";

const state = {
  categories: "",
  course: "",
};

const mutations = {
  SET_CATEGORIES(store, payload) {
    store.categories = payload;
  },

  SET_COURSE_INFORMATION(store, payload) {
    store.course = payload;
  },
};

const actions = {
  loadCategoriesList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/categories`)
        .then((response) => {
          commit("SET_CATEGORIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCoursesList(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/courses`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  participateInCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/courses/${data}/participate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  participateInPaidCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/courses/${data}/participate/paid`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserCourses(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/me/courses`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
