import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vClickOutside from "click-outside-vue3";
import moment from "moment";
import VueLatex from "vatex";

import "./scss/app.scss";
import Popper from "vue3-popper";

createApp(App)
  .use(store)
  .use(router)
  .use(vClickOutside)
  .use(VueLatex)
  .use(moment)
  .component("Popper", Popper)
  .mount("#app");
