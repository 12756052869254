import API from "@/api";

const state = {
  test: "",
  questions: "",
  testAnswers: {},
  verifiedAnswers: {},
  testResults: [],
};

const getters = {
  questionAnswers: (state) => (id) => {
    if (state.testResults.length) {
      return state.testResults.find((answer) => answer.question_id === id)
        ?.correct_answers;
    }

    return [];
  },
};

const mutations = {
  SET_TEST(state, payload) {
    state.test = payload;
  },

  SET_TEST_RESULTS(state, payload) {
    if (payload) {
      state.testResults = payload;
    }
  },

  SET_TEST_QUESTIONS(state, payload) {
    state.questions = payload.test_data;
  },

  SET_TEST_ANSWERS(state, payload) {
    state.testAnswers = payload;
  },

  SET_VERIFIED_ANSWERS(state, payload) {
    state.verifiedAnswers = payload;
  },

  CLEAR_TEST(state) {
    state.test = "";
    state.questions = "";
    state.testAnswers = {};
    state.verifiedAnswers = {};
    state.testResults = [];
  },

  CLEAR_TEST_DATA(state) {
    state.questions = "";
    state.testAnswers = {};
    state.verifiedAnswers = {};
    state.testResults = [];
  },
};

const actions = {
  loadTestInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test`
      )
        .then((response) => {
          commit("SET_TEST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadTestResults({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test/results`
      )
        .then((response) => {
          commit("SET_TEST_RESULTS", response.data.data.results.answers);
          commit("SET_VERIFIED_ANSWERS", response.data.data.results.verified);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startTest({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test/start`,
        { params: data.params }
      )
        .then((response) => {
          commit("SET_TEST_QUESTIONS", response.data.data);
          commit("SET_TEST_ANSWERS", response.data.data.answer_data);
          commit("SET_VERIFIED_ANSWERS", response.data.data.verified_data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveQuestionAnswer({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test/answer`,
        data.data
      )
        .then((response) => {
          commit("SET_TEST_QUESTIONS", response.data.data);
          commit("SET_TEST_ANSWERS", response.data.data.answer_data);
          commit("SET_VERIFIED_ANSWERS", response.data.data.verified_data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkQuestionAnswer({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test/check`,
        data.data
      )
        .then((response) => {
          commit("SET_VERIFIED_ANSWERS", response.data.data.verified_data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  completeTest({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/test/complete`
      )
        .then((response) => {
          commit("SET_TEST", response.data.data);

          commit("SET_VERIFIED_ANSWERS", response.data.data.results.verified);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
